<script>
import ConfigurationService from "@gf/gf-api-services/services/configuration/ConfigurationService"
import constants from "./constants"

export default {
  props:{
    baseDomain: {
      type: String,
      default: "gofundraise.com.au"
    },
     apiDomain: {
      type: String,
      default: "api.gofundraise.com"
    },
  },
  data() {
    return {
      configService: null
    }
  },
  mounted() {
    this.configService = new ConfigurationService(this.$gfLogger, this.apiDomain)
    this.$gfLogger.logInfo("Mounted Configuration Loader Mixin");
  },

  methods: {
    async $_ConfigurationLoaderMixin_GetCookieConsentSettings(regionId, eventId) {
      this.$gfLogger.logInfo("$_ConfigurationLoaderMixin_GetCookieConsentSettings()", {regionId: regionId, eventId: eventId});
      return await this.configService.getSettings({
        SettingsContext: {
          Region: regionId,
          EventId: eventId
        },
        Types: [
          {
            ConfigurationName: constants.cookieConfigurationType,
            ConfigurationSubType: constants.cookieConfigurationSubType
          }
        ]
      });
    }
  }
}
</script>

