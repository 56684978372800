import constants from "../constants"
import moment from "moment";

export default {
    state: {
        cookieValue: { }
    },

    acceptAllCookies() {
        this.state.cookieValue[constants.cookieTypes.mandatory] = 1
        this.state.cookieValue[constants.cookieLastUpdated] = moment().format(); 
    }
}