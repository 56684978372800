export default {
    default: {
        "bannerMessage": `<span style='color: #fff'>
            This website uses cookies to ensure you get the best experience on our website. 
            <div>
            You can find out more by reviewing our <a style="color: #fff;font-weight:bold;" href='/privacy-policy' target="_blank">Privacy Policy</a>
            </div>
        </span>`,
        "acceptButton": "<button class='btn btn-primary'>Accept</button>",
        "backgroundColour": "#000",
    },
};
